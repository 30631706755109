.footer__section {
  background: #1d1d1f;
  padding-top: 100px;
  height: 100vh;
  position: relative;
}

.footer__section .footer__link a {
  font-weight: bold !important;
  color: #fff !important;
  margin-left: 10px;
}
.sub__link,
.footer__link {
  color: #ffffff;
  font-weight: bold;
}

.footer__link {
  padding-top: 50px;
}

.footer__bottom__link {
  position: absolute;
  bottom: 20px;
  width: 100%;
  font-weight: bold;
  left: 0;
}

.footer__bottom__link .store__link img {
  /* width: 25%; */
  display: inline-block;
  margin: 10px;
}
.footer__section .social__link img {
  width: 22px;
  display: inline-block;
  margin: 10px;
}

a,
a:hover {
  text-decoration: none;
  color: #ffffff;
}

.order__btn {
  background: #ffffff;
  color: #277196;
  width: 20%;
  display: block;
  margin: auto;
  border-radius: 50px;
}

@media only screen and (max-width: 600px) {
  .footer__section {
    padding-top: 10px;
  }
  .footer__link {
    padding-top: 0;
  }

  .footer__bottom__link img {
    width: auto;
  }

  .order__btn {
    width: auto;
  }

  .footer__section .footer__link a {
    font-size: 14px;
  }
  .sub__link,
  .footer__link a {
    font-size: 14px;
  }
}
