@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

h1,
p,
h2 {
  color: #1d1d1f;
}

h1,
h2 {
  font-weight: bold !important;
}

/* body::-webkit-scrollbar {
  display: none;
} */

/* Scrollbar Styling */

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background-color: #ebebeb;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #59c7a0;
}

[data-ca3_icon]::before {
  font-weight: normal;
  content: attr(data-ca3_icon);
}

.ca3-scroll-down-arrow {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2hldnJvbl90aGluX2Rvd24iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiBmaWxsPSJ3aGl0ZSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTE3LjQxOCw2LjEwOWMwLjI3Mi0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwYzAuMjcsMC4yNjgsMC4yNzEsMC43MDEsMCwwLjk2OWwtNy45MDgsNy44M2MtMC4yNywwLjI2OC0wLjcwNywwLjI2OC0wLjk3OSwwbC03LjkwOC03LjgzYy0wLjI3LTAuMjY4LTAuMjctMC43MDEsMC0wLjk2OWMwLjI3MS0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwTDEwLDEzLjI1TDE3LjQxOCw2LjEwOXoiLz48L3N2Zz4=);
  background-size: contain;
  background-repeat: no-repeat;
}

.ca3-scroll-down-link {
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin: 0px 0 0 -40px;
  line-height: 60px;
  /* position: absolute;
left: 50%; */
  display: block;
  margin: auto;
  bottom: 20px;
  color: #fff;
  z-index: 100;
  text-decoration: none;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);

  -webkit-animation: ca3_fade_move_down 2s ease-in-out infinite;
  animation: ca3_fade_move_down 2s ease-in-out infinite;
}

/*animated scroll arrow animation*/
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -20px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 20px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -20px);
            transform: translate(0, -20px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 20px);
            transform: translate(0, 20px);
    opacity: 0;
  }
}

.CookieConsent {
  font-family: "Poppins", sans-serif !important;

}

#rcc-confirm-button {
  border-radius: 50px !important;
  padding: 5px 30px !important;
}

.about__main {
  background: url(/static/media/about_bg.7904fc71.png), #000000;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: bottom bottom;
  background-size: cover;
  margin-top: -60px;
}

/* .about__main .container {
  position: absolute;
  left: 8%;
} */

.about__main h1 {
  font-size: 64px;
  line-height: 1;
}

.about__main h5 {
  font-size: 36px;
}

.about__sub__text p {
  line-height: 3;
  font-size: 24px;
  text-align: justify;
}

.about__team p {
  line-height: 1.2;
  text-align: justify;
}

.about__family {
  background: #f2f2f2;
}

.about__family p {
  text-align: justify;
  line-height: 2.5;
}

@media only screen and (max-width: 768px) {
  .about__main h1 {
    font-size: 36px;
  }
  .about__main h5 {
    font-size: 24px;
  }
  .about__sub__text p {
    font-size: 16px;
  }
  .about__team p {
    /* font-size: 12px; */
  }
}

.footer__section {
  background: #1d1d1f;
  padding-top: 100px;
  height: 100vh;
  position: relative;
}

.footer__section .footer__link a {
  font-weight: bold !important;
  color: #fff !important;
  margin-left: 10px;
}
.sub__link,
.footer__link {
  color: #ffffff;
  font-weight: bold;
}

.footer__link {
  padding-top: 50px;
}

.footer__bottom__link {
  position: absolute;
  bottom: 20px;
  width: 100%;
  font-weight: bold;
  left: 0;
}

.footer__bottom__link .store__link img {
  /* width: 25%; */
  display: inline-block;
  margin: 10px;
}
.footer__section .social__link img {
  width: 22px;
  display: inline-block;
  margin: 10px;
}

a,
a:hover {
  text-decoration: none;
  color: #ffffff;
}

.order__btn {
  background: #ffffff;
  color: #277196;
  width: 20%;
  display: block;
  margin: auto;
  border-radius: 50px;
}

@media only screen and (max-width: 600px) {
  .footer__section {
    padding-top: 10px;
  }
  .footer__link {
    padding-top: 0;
  }

  .footer__bottom__link img {
    width: auto;
  }

  .order__btn {
    width: auto;
  }

  .footer__section .footer__link a {
    font-size: 14px;
  }
  .sub__link,
  .footer__link a {
    font-size: 14px;
  }
}

.navbar__dark .nav-link {
  font-weight: bold;
  color: #181b21 !important;
  font-size: 16px;
  opacity: 0.7;
}
.navbar__dark .nav-link:hover {
  color: #277196 !important;
  font-weight: bold;
}

.navbar__dark .navbar {
  /* background: transparent !important; */
  background: #ffffff !important;
}

.navbar__dark .navbar li {
  margin-right: 50px;
}

.navbar__dark .active {
  color: #181b21 !important;
  font-weight: bold;
  opacity: 1;
}

.navbar__dark .sustlabs__border {
  border-right: 1px solid #626262;
}

.navbar__dark .sustlabs__border img {
  margin: -10px;
}

.navbar__dark #menuToggle {
  display: block;
  position: absolute;
  top: 35px;
  right: 10px;

  z-index: 1;

  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
}

.navbar__dark #menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

/*
  * Just a quick hamburger
  */
.navbar__dark #menuToggle span {
  display: block;

  height: 2px;
  margin-bottom: 5px;
  position: relative;

  background: #cdcdcd;
  border-radius: 3px;

  z-index: 1;

  -webkit-transform-origin: 4px 0px;

          transform-origin: 4px 0px;

  transition: background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease,
    -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease,
    -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  color: #ffffff !important;
}

.navbar__dark #menuToggle .span1 {
  width: 35px;
}

.navbar__dark #menuToggle .span2 {
  width: 28px;
  left: 5px;
}

.navbar__dark #menuToggle .span3 {
  width: 20px;
  left: 12px;
}

.navbar__dark #menuToggle span:first-child {
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}

.navbar__dark #menuToggle span:nth-last-child(2) {
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
}

/* 
  * Transform all the slices of hamburger
  * into a crossmark.
  */
.navbar__dark #menuToggle input:checked ~ span {
  opacity: 1;
  -webkit-transform: rotate(45deg) translate(-2px, -1px);
          transform: rotate(45deg) translate(-2px, -1px);
  /* background: #232323; */
  left: 0;
  width: 28px !important;
}

/*
  * But let's hide the middle one.
  */
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  -webkit-transform: rotate(0deg) scale(0.2, 0.2);
          transform: rotate(0deg) scale(0.2, 0.2);
  width: 28px !important;
}

/*
  * Ohyeah and the last one should go the other direction
  */
.navbar__dark #menuToggle input:checked ~ span:nth-last-child(2) {
  opacity: 1;
  -webkit-transform: rotate(-45deg) translate(0, -1px);
          transform: rotate(-45deg) translate(0, -1px);
}
.navbar__dark #menu {
  position: absolute;
  width: 400px;
  margin: -100px 0 0 0;
  padding: 50px;
  padding-top: 100px;
  right: -100px;

  background: #000000;
  list-style-type: none !important;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  -webkit-transform-origin: 0% 0%;

          transform-origin: 0% 0%;
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0);

  transition: -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  height: 110vh;
  box-shadow: 0px 25px 50px #062a2d59;
}

.navbar__dark #menu li {
  padding: 10px 0;
  font-size: 22px;
  color: #787878;
  font-size: 16px;
}

/*
  * And let's fade it in from the left
  */
.navbar__dark #menuToggle input:checked ~ ul {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  opacity: 1;
  height: 106vh;
}

@media only screen and (max-width: 768px) {
  .navbar__dark #menuToggle {
    display: none;
  }
  .navbar__dark .navbar {
    /* background-color: #000000 !important; */
  }
}

.nav-link {
  font-weight: bold;
  color: #ffffff !important;
  font-size: 16px;
  opacity: 0.7;
}

.navbar {
  background: transparent !important;
}

.navbar li {
  margin-right: 50px;
}

.active {
  /* color: #181b21 !important; */
  font-weight: bold;
  opacity: 1;
}

.sustlabs__border {
  border-right: 1px solid #626262;
}

.sustlabs__border img {
  margin: -10px;
}

#menuToggle {
  display: block;
  position: absolute;
  top: 35px;
  right: 10px;

  z-index: 1;

  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

/*
  * Just a quick hamburger
  */
#menuToggle span {
  display: block;

  height: 2px;
  margin-bottom: 5px;
  position: relative;

  background: #cdcdcd;
  border-radius: 3px;

  z-index: 1;

  -webkit-transform-origin: 4px 0px;

          transform-origin: 4px 0px;

  transition: background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease,
    -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease,
    -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  color: #ffffff !important;
}

#menuToggle .span1 {
  width: 35px;
}

#menuToggle .span2 {
  width: 28px;
  left: 5px;
}

#menuToggle .span3 {
  width: 20px;
  left: 12px;
}

#menuToggle span:first-child {
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
}

/* 
  * Transform all the slices of hamburger
  * into a crossmark.
  */
#menuToggle input:checked ~ span {
  opacity: 1;
  -webkit-transform: rotate(45deg) translate(-2px, -1px);
          transform: rotate(45deg) translate(-2px, -1px);
  /* background: #232323; */
  left: 0;
  width: 28px !important;
}

/*
  * But let's hide the middle one.
  */
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  -webkit-transform: rotate(0deg) scale(0.2, 0.2);
          transform: rotate(0deg) scale(0.2, 0.2);
  width: 28px !important;
}

/*
  * Ohyeah and the last one should go the other direction
  */
#menuToggle input:checked ~ span:nth-last-child(2) {
  opacity: 1;
  -webkit-transform: rotate(-45deg) translate(0, -1px);
          transform: rotate(-45deg) translate(0, -1px);
}
#menu {
  position: absolute;
  width: 400px;
  margin: -100px 0 0 0;
  padding: 50px;
  padding-top: 100px;
  right: -100px;

  background: #000000;
  list-style-type: none !important;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  -webkit-transform-origin: 0% 0%;

          transform-origin: 0% 0%;
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0);

  transition: -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  height: 110vh;
  box-shadow: 0px 25px 50px #062a2d59;
}

#menu li {
  padding: 10px 0;
  font-size: 22px;
  color: #787878;
  font-size: 16px;
}

/*
  * And let's fade it in from the left
  */
#menuToggle input:checked ~ ul {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  opacity: 1;
  height: 106vh;
}

@media only screen and (max-width: 768px) {
  #menuToggle {
    display: none;
  }
  .navbar {
    /* background-color: #000000 !important; */
  }
}

.contact__map__section {
  background-color: #f2f2f2;
}

.contact__details h4 {
  font-weight: bold;
}
.contact__details h4,
p {
  color: #1d1d1f;
  text-align: center;
}

.br__right {
  border-right: 1px solid #000000;
}

@media only screen and (max-width: 600px) {
  .br__right {
    border: none;
  }
}

.faq__questions h4 {
  color: #59c7a0;
  font-weight: bold;
}

.accordion {
  margin-top: 40px;
}
.accordion .card {
  border: none;
  margin-bottom: 20px;
}
.accordion .card p {
  background: url(https://cdn0.iconfinder.com/data/icons/entypo/91/arrow56-512.png)
    no-repeat calc(100% - 0px) center;
  background-size: 15px;
  cursor: pointer;
  font-size: 14px !important;
  font-weight: bold;
  text-align: start;
}
.accordion .card p.collapsed {
  background-image: url(https://cdn0.iconfinder.com/data/icons/arrows-android-l-lollipop-icon-pack/24/expand2-256.png);
}
.accordion .card-body {
  padding-left: 0;
  padding-right: 0;
}

.accordion .card .collapse {
  width: 90%;
}

.home__main__section {
  /* background-color: #000000; */
  height: 100vh;
  background-image: url(/static/media/topBg.ae959287.png);
  /* background-size: contain;
    background-position: top;
    position: relative;
    background-repeat: no-repeat; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Roboto", sans-serif !important;
  margin-top: -56px;
}

.home__main__section {
  padding-top: 130px;
}

.home__main__section h1 {
  font-size: 56px;
  font-weight: bold;
}

.home__main__section h1 span {
  /* color: #277196; */
  color: #ffffff;
  line-height: 2;
}

.home__main__section .MuiButton-root {
  background-color: #ffffff;
  color: #277196 !important;
  /* border: 2px solid #59c7a0; */
  margin-right: 200px;
  border-radius: 50px;
  padding: 10px 70px;
}
.home__main__section .MuiButton-root a {
  color: #277196;
  font-weight: bold;
  text-decoration: none;
}
.home__main__section .MuiButton-root:hover {
  background-color: #ffffff;
  color: #277196;
  /* border: 2px solid #59c7a0; */
}

.built__section,
.built__trust__section {
  color: #1d1d1f;
  /* height: 100vh; */
}

.built__trust__section,
.making__noise {
  height: 100vh;
}

.built__section span {
  font-size: 18px;
  margin-right: 30px;
}

.making__noise,
.built__trust__section {
  padding-top: 50px;
}

.making__noise h1,
.built__trust__section h1 {
  font-weight: bold;
}

.making__noise img {
  /* width: 70%; */
  display: block;
  margin: auto;
}

.built__trust__section img {
  width: 85%;
  display: block;
  margin: auto;
}

.making__noise img {
  width: 85%;
  display: block;
  margin: auto;
}

/* .ohm__Homeanimation {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: baseline;
} */

.ohm__Homeanimation h1,
.built__section h1,
.built__trust__section h1,
.making__noise h1 {
  font-size: 36px;
  font-weight: bold;
}
.ohm__Homeanimation p,
.built__section p,
.built__trust__section p,
.making__noise p {
  font-size: 24px;
}

@media only screen and (max-width: 768px) {
  .built__trust__section,
.making__noise {
  height: auto;
}

  .home__main__section .MuiButton-root {
    width: 100%;
  }
  .home__main__section h1 {
    font-size: 32px;
  }
  .ohm__Homeanimation p {
    position: static;
    position: initial;
    text-align: center;
    font-size: 12px;
  }
  .built__section span {
    margin-right: 5px;
  }

  .ohm__Homeanimation h1,
  .built__section h1,
  .built__trust__section h1,
  .making__noise h1 {
    font-size: 28px !important;
  }
  .ohm__Homeanimation p,
  .built__section p,
  .built__trust__section p,
  .making__noise p {
    font-size: 18px !important;
  }
  .making__noise img {
    width: auto;
  }
  .built__trust__section img {
    width: auto;
  }
}

.scroll-container {
  -ms-scroll-snap-type: mandatory;
      scroll-snap-type: mandatory;
}

.scroll-area {
  scroll-snap-align: start;
}

.scroll-container,
.scroll-area {
  /* margin: 0 auto; */
}

.partner__form {
  background: #f2f2f2;
}
.backgroundBg {
  background-color: transparent !important;
}

.privacy-policy h1,
.privacy-policy h5 {
  color: #1d1d1f !important;
  line-height: 2;
}

.privacy-policy h4 {
  color: #1d1d1f !important;
  font-weight: 600 !important;
}

.privacy-policy p,
.privacy-policy li {
  font-size: 18px;
  line-height: 2;
  color: #000000;
  text-align: justify;
}

.b__gray {
  background-color: #f2f2f2;
  /* padding: 0px 120px; */
}

@media only screen and (max-width: 768px) {
  .b__gray {
    /* padding: 10px; */
  }
}

.product__section {
  background-color: #1d1d1f;
  height: 102vh;
  /* padding-top: 200px; */
  margin-top: -65px;
  display: flex;
  align-items: center;
}
.product__section__firstCol {
  padding-top: 100px;
}
.product__section h1 {
  color: #277196;
  line-height: 1.5;
  font-weight: bold;
  font-size: 56px;
}

.product__section .MuiButton-root {
  background: #252527;
  color: #0096d6;
  font-weight: 900;
  padding: 10px 30px;
  border-radius: 50px;
}

.gray__bg {
  background: #f2f2f2;
  /* margin-left: 30px;
  margin-right: 30px; */
}

.ohm__smartHome video {
  width: 90%;
  display: block;
  margin: auto;
}

.know__btn {
  background: #277196;
  padding: 10px 30px;
  color: #fff;
  border-radius: 50px;
  display: block;
}

.meet__ohm__section p {
  text-align: start;
}

@media only screen and (max-width: 768px) {
  .product__section {
    height: auto !important;
  }
  .product__section h1 {
    font-size: 32px;
  }
  .ohm__smartHome video {
    width: 100%;
    display: block;
    margin: auto;
  }
}

.map {
  position: relative;
  width: 100%;
}
#starshine {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  /* z-index: 1; */
  overflow: hidden;
}

.shine {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;

  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAEa0lEQVRoge2ZT2xUVRTGv+++oRQU/Aul/JEQiIiRCColUVdGYzURdsSEAEsDCX9EXJmYYeGOFY0rookajRZbSUzBxERxUdkgiSQmEBRESkGJJUCL7bx77ufilUJLZ+a96Rswpic5mUxy557fd865592ZASZt0iatbiadbJFOrqpnDNZrY+nX2UDpjGAgwiLyyb/qEcfVY1MACBjaIvjpgJ8eUNpSrzh1qYB0aqpw/SxgTYAB8JcILSSf/SfvWHWqQP9GwDcBHkAMwM8ChtbXI1LuAiRRsB0JeIxEhIdguyTlXvE6VOBoKxA/fgP8Fl8KHHw572j5VwDxznHgkVQhfjPveLmWVOp+QrDjgDE5vLc7Ea8gX/85r5i5ViAgfgvwLFcBwCMgbM8zZm4VkLpnC4NnAWssl/1EhA0Rg4vINy7kETe3CgQMbgV8Y/nsj0ykqQFuc15xc6mA9OM04eofgD1cJutjvY8YeIR8e2CisTMLSGb54SZA8wDNBcL8AP8cYetTwgMwCPapQ+gG4h5AvcDQeaD4JwlNSIB0anECFy8AQnOAn0/4eUCYC/gFgM0BbEr5Pq8MPq4YjayPAbso+HMO1ivpPJzvEfyFCDoHxD3kh6fLCpBO7gbCu6oRZILw6ZxxMeJnu28wF0bnP5wWvJI5nhU++3opc3Ik2JmyFUiqcGyDYB9Ub5M7mPVkfxNtc4Ht+yoKSEQceVGwTsBm/AeyDsCui7auwP1dY1nLTiHp8CrBugCbdXfgR/bvc4zXkJ3d43FWHKPSwcUCvgFsyV3IOoBw1tG3kh0nyjFWfQ5IXXOE0iHAVtzBrAOwXxytlezsqcSX6kEmtd87fCZeygJSK7xgP0TkWnL/lWpsqe5C5Lp+4tJrQvxFpZvmjTuP5IcnTRof/c2N8Aci8pU08EDGq4QkBuzbQ/ideWcdMEDhfeeWbyOLIS1TTZc5U9tewm+tHX6csyTfFkUHtmVlKVRfMp75/pstMMGs3/RrtZDUJICIV+eS9VuctNW1sWQ0qeiEKZcBm5kX/PCaa47P35+l/4GavpE1PCb5memmzOgJU9ltBvDd0qw0mQUYSi3px2PaUZqsT/auswAGX0VAlqyPXs9gmQVkP8T0LRPr9fLrxewCMj7Iio1B/VcAa0gB3gdaEQiAfBGwB6sJFawU8dp95O+DaZkyttDVpwDfUKXXAxR/4uiXRexsi3igzVFLIL8X8L5ceyl5bfAorMxClElAQGip1OuE/97Rr4yijo3kVyP/yJBdl6Po2+2OYTkRHxoLrlveO4RMbZStAiMH+LYsnhPjTc61v0B2HC/3cfLwCeeOvCrGawD/m8arRIjrKIClljHwA5Dtdmx8tMAvP067TYHHvnaMlpF+B+CvjhLBbAJSH2Jp8wNBQ38j+eU5QPaRc0PvkJ0T+o1TWthsAe+BfhNgDjBFLD1EXrmc5vMZBGy4J4g/CeFiRL+L/Pxo7di3W0nNzzjFewRrKnDa02Tv9Tz3n7RJ+7/av+stPTwgWnTNAAAAAElFTkSuQmCC);
  /* background-image: url("../Assets/star.svg"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  overflow: hidden;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  z-index: 2;
  color: tansparent;
  -moz-opacity: 0;
  opacity: 0;
  animation: glitter 2s linear 0s infinite normal;
  -webkit-animation: glitter 2s linear 0s infinite normal;
  -moz-animation: glitter 8s linear 0s infinite normal;
  -ms-animation: glitter 8s linear 0s infinite normal;
  -o-animation: glitter 8s linear 0s infinite normal;
}

.shine.small {
  width: 10px;
  height: 10px;
}

.shine.medium {
  width: 30px;
  height: 30px;
}

.shine.large {
  width: 50px;
  height: 50px;
}

/*CSS3 keyframes for glittering effect*/
@-webkit-keyframes glitter {
  0% {
    -webkit-transform: scale(0.8) rotate(30deg);
    opacity: 0.1;
  }
  25% {
    -webkit-transform: scale(0.8) rotate(30deg);
    opacity: 0.2;
  }
  50% {
    -webkit-transform: scale(0.8) rotate(30deg);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.8) rotate(30deg);
    opacity: 0.3;
  }
}
@keyframes glitter {
  0% {
    -webkit-transform: scale(0.8) rotate(30deg);
    opacity: 0.1;
  }
  25% {
    -webkit-transform: scale(0.8) rotate(30deg);
    opacity: 0.2;
  }
  50% {
    -webkit-transform: scale(0.8) rotate(30deg);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.8) rotate(30deg);
    opacity: 0.3;
  }
}

.map__container {
  width: 695px;
  height: 611px;
}
/* @-webkit-keyframes glitter {
  0% {
    -webkit-transform: scale(0.8) rotate(30deg);
    opacity: 0.5;
  }
  25% {
    -webkit-transform: scale(0.8) rotate(30deg);
    opacity: 0.4;
  }
  50% {
    -webkit-transform: scale(0.8) rotate(30deg);
    opacity: 0.8;
  }
  100% {
    -webkit-transform: scale(0.8) rotate(30deg);
    opacity: 0.6;
  }
} */

.sales-refund h1,
.sales-refund h5 {
  color: #1d1d1f !important;
  line-height: 2;
}

.sales-refund h4 {
  color: #1d1d1f !important;
  font-weight: 600 !important;
}

.sales-refund p,
.sales-refund li {
  font-size: 18px;
  line-height: 2;
  color: #000000;
  text-align: justify;
}

.b__gray {
  background-color: #f2f2f2;
  /* padding: 0px 120px; */
}

@media only screen and (max-width: 768px) {
  .b__gray {
    /* padding: 10px; */
  }
}

.technology__section {
  background: url(/static/media/tech_bg.df414b2a.png);
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.technology__section button {
  color: #fff !important;
  background-color: #277196 !important;
  padding: 10px 50px;
  border-radius: 50px;
}

.technology__section .container {
  position: absolute;
  top: 30%;
  left: 10%;
}

.technology__section h1 {
  font-size: 56px;
  font-weight: bold;
  text-align: justify;
}

.technology__building {
  background: url(/static/media/bg-2.816b1ad9.png);
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.technology__building h1 {
  color: #ffffff !important;
  font-size: 52px;
  text-align: right;
  font-weight: bold;
}
.technology__building h2,
.technology__building p {
  color: #ffffff !important;
  text-align: center;
}

.technology__building h2 {
  font-weight: bold;
}

.technology__building h4 {
  color: #ffffff !important;
  line-height: 1.5;
  text-align: right;
  font-size: 24px;
}

.ohm__group img {
  object-fit: contain;
}
.technology__ticking {
  background-color: #f2f2f2;
}
.technology__ticking__sub p {
  font-weight: bold;
}

.technology__ticking__sub img {
  width: 40px;
  display: block;
  /* margin: auto; */
}

@media only screen and (max-width: 768px) {
  .technology__section h1 {
    font-size: 38px;
    text-align: center;
  }
  .technology__section h4 {
    text-align: center;
  }
  .technology__section button {
    width: 80%;
    display: block;
    margin: auto;
  }
  .technology__section .container {
    left: 0%;
    top: 20%;
  }

  .technology__building h1 {
    color: #ffffff !important;
    font-size: 36px;
    text-align: center;
  }
  .technology__building h4 {
    font-size: 16px;
    text-align: center;
  }

  .ohm__group .reverse__section {
    flex-direction: column-reverse;
  }

  .ohm__group h4,
  .ohm__group p,
  .ohm__group small {
    text-align: center;
  }
}

.terms-and-condition h1,
.terms-and-condition h5 {
  color: #1d1d1f !important;
  line-height: 2;
}

.terms-and-condition h4 {
  color: #1d1d1f !important;
  font-weight: 600 !important;
}

.terms-and-condition p,
.terms-and-condition li {
  font-size: 18px;
  line-height: 2;
  color: #000000;
  text-align: justify;
}

.b__gray {
  background-color: #f2f2f2;
  /* padding: 0px 120px; */
}

@media only screen and (max-width: 768px) {
  .b__gray {
    /* padding: 10px; */
  }
}

.compliance {
    height: 70vh;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    color: #212529;
    text-align: justify;
  }

  .clickHere{
    color: #277196 ;
  }
  .clickHere:hover{
    color: #20c997;
  }
  .title{
    font-weight: 400 ;
  }
