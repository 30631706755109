.technology__section {
  background: url("../Assets/technology//tech_bg.png");
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.technology__section button {
  color: #fff !important;
  background-color: #277196 !important;
  padding: 10px 50px;
  border-radius: 50px;
}

.technology__section .container {
  position: absolute;
  top: 30%;
  left: 10%;
}

.technology__section h1 {
  font-size: 56px;
  font-weight: bold;
  text-align: justify;
}

.technology__building {
  background: url("../Assets/technology//bg-2.png");
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.technology__building h1 {
  color: #ffffff !important;
  font-size: 52px;
  text-align: right;
  font-weight: bold;
}
.technology__building h2,
.technology__building p {
  color: #ffffff !important;
  text-align: center;
}

.technology__building h2 {
  font-weight: bold;
}

.technology__building h4 {
  color: #ffffff !important;
  line-height: 1.5;
  text-align: right;
  font-size: 24px;
}

.ohm__group img {
  object-fit: contain;
}
.technology__ticking {
  background-color: #f2f2f2;
}
.technology__ticking__sub p {
  font-weight: bold;
}

.technology__ticking__sub img {
  width: 40px;
  display: block;
  /* margin: auto; */
}

@media only screen and (max-width: 768px) {
  .technology__section h1 {
    font-size: 38px;
    text-align: center;
  }
  .technology__section h4 {
    text-align: center;
  }
  .technology__section button {
    width: 80%;
    display: block;
    margin: auto;
  }
  .technology__section .container {
    left: 0%;
    top: 20%;
  }

  .technology__building h1 {
    color: #ffffff !important;
    font-size: 36px;
    text-align: center;
  }
  .technology__building h4 {
    font-size: 16px;
    text-align: center;
  }

  .ohm__group .reverse__section {
    flex-direction: column-reverse;
  }

  .ohm__group h4,
  .ohm__group p,
  .ohm__group small {
    text-align: center;
  }
}
