.map {
  position: relative;
  width: 100%;
}
#starshine {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  /* z-index: 1; */
  overflow: hidden;
}

.shine {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;

  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAEa0lEQVRoge2ZT2xUVRTGv+++oRQU/Aul/JEQiIiRCColUVdGYzURdsSEAEsDCX9EXJmYYeGOFY0rookajRZbSUzBxERxUdkgiSQmEBRESkGJJUCL7bx77ufilUJLZ+a96Rswpic5mUxy557fd865592ZASZt0iatbiadbJFOrqpnDNZrY+nX2UDpjGAgwiLyyb/qEcfVY1MACBjaIvjpgJ8eUNpSrzh1qYB0aqpw/SxgTYAB8JcILSSf/SfvWHWqQP9GwDcBHkAMwM8ChtbXI1LuAiRRsB0JeIxEhIdguyTlXvE6VOBoKxA/fgP8Fl8KHHw572j5VwDxznHgkVQhfjPveLmWVOp+QrDjgDE5vLc7Ea8gX/85r5i5ViAgfgvwLFcBwCMgbM8zZm4VkLpnC4NnAWssl/1EhA0Rg4vINy7kETe3CgQMbgV8Y/nsj0ykqQFuc15xc6mA9OM04eofgD1cJutjvY8YeIR8e2CisTMLSGb54SZA8wDNBcL8AP8cYetTwgMwCPapQ+gG4h5AvcDQeaD4JwlNSIB0anECFy8AQnOAn0/4eUCYC/gFgM0BbEr5Pq8MPq4YjayPAbso+HMO1ivpPJzvEfyFCDoHxD3kh6fLCpBO7gbCu6oRZILw6ZxxMeJnu28wF0bnP5wWvJI5nhU++3opc3Ik2JmyFUiqcGyDYB9Ub5M7mPVkfxNtc4Ht+yoKSEQceVGwTsBm/AeyDsCui7auwP1dY1nLTiHp8CrBugCbdXfgR/bvc4zXkJ3d43FWHKPSwcUCvgFsyV3IOoBw1tG3kh0nyjFWfQ5IXXOE0iHAVtzBrAOwXxytlezsqcSX6kEmtd87fCZeygJSK7xgP0TkWnL/lWpsqe5C5Lp+4tJrQvxFpZvmjTuP5IcnTRof/c2N8Aci8pU08EDGq4QkBuzbQ/ideWcdMEDhfeeWbyOLIS1TTZc5U9tewm+tHX6csyTfFkUHtmVlKVRfMp75/pstMMGs3/RrtZDUJICIV+eS9VuctNW1sWQ0qeiEKZcBm5kX/PCaa47P35+l/4GavpE1PCb5memmzOgJU9ltBvDd0qw0mQUYSi3px2PaUZqsT/auswAGX0VAlqyPXs9gmQVkP8T0LRPr9fLrxewCMj7Iio1B/VcAa0gB3gdaEQiAfBGwB6sJFawU8dp95O+DaZkyttDVpwDfUKXXAxR/4uiXRexsi3igzVFLIL8X8L5ceyl5bfAorMxClElAQGip1OuE/97Rr4yijo3kVyP/yJBdl6Po2+2OYTkRHxoLrlveO4RMbZStAiMH+LYsnhPjTc61v0B2HC/3cfLwCeeOvCrGawD/m8arRIjrKIClljHwA5Dtdmx8tMAvP067TYHHvnaMlpF+B+CvjhLBbAJSH2Jp8wNBQ38j+eU5QPaRc0PvkJ0T+o1TWthsAe+BfhNgDjBFLD1EXrmc5vMZBGy4J4g/CeFiRL+L/Pxo7di3W0nNzzjFewRrKnDa02Tv9Tz3n7RJ+7/av+stPTwgWnTNAAAAAElFTkSuQmCC);
  /* background-image: url("../Assets/star.svg"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  overflow: hidden;
  animation-delay: 0s;
  animation-timing-function: ease-in;
  z-index: 2;
  color: tansparent;
  -moz-opacity: 0;
  opacity: 0;
  animation: glitter 2s linear 0s infinite normal;
  -webkit-animation: glitter 2s linear 0s infinite normal;
  -moz-animation: glitter 8s linear 0s infinite normal;
  -ms-animation: glitter 8s linear 0s infinite normal;
  -o-animation: glitter 8s linear 0s infinite normal;
}

.shine.small {
  width: 10px;
  height: 10px;
}

.shine.medium {
  width: 30px;
  height: 30px;
}

.shine.large {
  width: 50px;
  height: 50px;
}

/*CSS3 keyframes for glittering effect*/
@keyframes glitter {
  0% {
    -webkit-transform: scale(0.8) rotate(30deg);
    opacity: 0.1;
  }
  25% {
    -webkit-transform: scale(0.8) rotate(30deg);
    opacity: 0.2;
  }
  50% {
    -webkit-transform: scale(0.8) rotate(30deg);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.8) rotate(30deg);
    opacity: 0.3;
  }
}

.map__container {
  width: 695px;
  height: 611px;
}
/* @-webkit-keyframes glitter {
  0% {
    -webkit-transform: scale(0.8) rotate(30deg);
    opacity: 0.5;
  }
  25% {
    -webkit-transform: scale(0.8) rotate(30deg);
    opacity: 0.4;
  }
  50% {
    -webkit-transform: scale(0.8) rotate(30deg);
    opacity: 0.8;
  }
  100% {
    -webkit-transform: scale(0.8) rotate(30deg);
    opacity: 0.6;
  }
} */
