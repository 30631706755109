.contact__map__section {
  background-color: #f2f2f2;
}

.contact__details h4 {
  font-weight: bold;
}
.contact__details h4,
p {
  color: #1d1d1f;
  text-align: center;
}

.br__right {
  border-right: 1px solid #000000;
}

@media only screen and (max-width: 600px) {
  .br__right {
    border: none;
  }
}
