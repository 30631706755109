.nav-link {
  font-weight: bold;
  color: #ffffff !important;
  font-size: 16px;
  opacity: 0.7;
}

.navbar {
  background: transparent !important;
}

.navbar li {
  margin-right: 50px;
}

.active {
  /* color: #181b21 !important; */
  font-weight: bold;
  opacity: 1;
}

.sustlabs__border {
  border-right: 1px solid #626262;
}

.sustlabs__border img {
  margin: -10px;
}

#menuToggle {
  display: block;
  position: absolute;
  top: 35px;
  right: 10px;

  z-index: 1;

  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

/*
  * Just a quick hamburger
  */
#menuToggle span {
  display: block;

  height: 2px;
  margin-bottom: 5px;
  position: relative;

  background: #cdcdcd;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  color: #ffffff !important;
}

#menuToggle .span1 {
  width: 35px;
}

#menuToggle .span2 {
  width: 28px;
  left: 5px;
}

#menuToggle .span3 {
  width: 20px;
  left: 12px;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/* 
  * Transform all the slices of hamburger
  * into a crossmark.
  */
#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  /* background: #232323; */
  left: 0;
  width: 28px !important;
}

/*
  * But let's hide the middle one.
  */
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
  width: 28px !important;
}

/*
  * Ohyeah and the last one should go the other direction
  */
#menuToggle input:checked ~ span:nth-last-child(2) {
  opacity: 1;
  transform: rotate(-45deg) translate(0, -1px);
}
#menu {
  position: absolute;
  width: 400px;
  margin: -100px 0 0 0;
  padding: 50px;
  padding-top: 100px;
  right: -100px;

  background: #000000;
  list-style-type: none !important;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(100%, 0);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  height: 110vh;
  box-shadow: 0px 25px 50px #062a2d59;
}

#menu li {
  padding: 10px 0;
  font-size: 22px;
  color: #787878;
  font-size: 16px;
}

/*
  * And let's fade it in from the left
  */
#menuToggle input:checked ~ ul {
  transform: scale(1, 1);
  opacity: 1;
  height: 106vh;
}

@media only screen and (max-width: 768px) {
  #menuToggle {
    display: none;
  }
  .navbar {
    /* background-color: #000000 !important; */
  }
}
