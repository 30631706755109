@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");
.home__main__section {
  /* background-color: #000000; */
  height: 100vh;
  background-image: url("../Assets/Home/topBg.png");
  /* background-size: contain;
    background-position: top;
    position: relative;
    background-repeat: no-repeat; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Roboto", sans-serif !important;
  margin-top: -56px;
}

.home__main__section {
  padding-top: 130px;
}

.home__main__section h1 {
  font-size: 56px;
  font-weight: bold;
}

.home__main__section h1 span {
  /* color: #277196; */
  color: #ffffff;
  line-height: 2;
}

.home__main__section .MuiButton-root {
  background-color: #ffffff;
  color: #277196 !important;
  /* border: 2px solid #59c7a0; */
  margin-right: 200px;
  border-radius: 50px;
  padding: 10px 70px;
}
.home__main__section .MuiButton-root a {
  color: #277196;
  font-weight: bold;
  text-decoration: none;
}
.home__main__section .MuiButton-root:hover {
  background-color: #ffffff;
  color: #277196;
  /* border: 2px solid #59c7a0; */
}

.built__section,
.built__trust__section {
  color: #1d1d1f;
  /* height: 100vh; */
}

.built__trust__section,
.making__noise {
  height: 100vh;
}

.built__section span {
  font-size: 18px;
  margin-right: 30px;
}

.making__noise,
.built__trust__section {
  padding-top: 50px;
}

.making__noise h1,
.built__trust__section h1 {
  font-weight: bold;
}

.making__noise img {
  /* width: 70%; */
  display: block;
  margin: auto;
}

.built__trust__section img {
  width: 85%;
  display: block;
  margin: auto;
}

.making__noise img {
  width: 85%;
  display: block;
  margin: auto;
}

/* .ohm__Homeanimation {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: baseline;
} */

.ohm__Homeanimation h1,
.built__section h1,
.built__trust__section h1,
.making__noise h1 {
  font-size: 36px;
  font-weight: bold;
}
.ohm__Homeanimation p,
.built__section p,
.built__trust__section p,
.making__noise p {
  font-size: 24px;
}

@media only screen and (max-width: 768px) {
  .built__trust__section,
.making__noise {
  height: auto;
}

  .home__main__section .MuiButton-root {
    width: 100%;
  }
  .home__main__section h1 {
    font-size: 32px;
  }
  .ohm__Homeanimation p {
    position: initial;
    text-align: center;
    font-size: 12px;
  }
  .built__section span {
    margin-right: 5px;
  }

  .ohm__Homeanimation h1,
  .built__section h1,
  .built__trust__section h1,
  .making__noise h1 {
    font-size: 28px !important;
  }
  .ohm__Homeanimation p,
  .built__section p,
  .built__trust__section p,
  .making__noise p {
    font-size: 18px !important;
  }
  .making__noise img {
    width: auto;
  }
  .built__trust__section img {
    width: auto;
  }
}

.scroll-container {
  scroll-snap-type: mandatory;
}

.scroll-area {
  scroll-snap-align: start;
}

.scroll-container,
.scroll-area {
  /* margin: 0 auto; */
}
