.about__main {
  background: url("../Assets/about/about_bg.png"), #000000;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: bottom bottom;
  background-size: cover;
  margin-top: -60px;
}

/* .about__main .container {
  position: absolute;
  left: 8%;
} */

.about__main h1 {
  font-size: 64px;
  line-height: 1;
}

.about__main h5 {
  font-size: 36px;
}

.about__sub__text p {
  line-height: 3;
  font-size: 24px;
  text-align: justify;
}

.about__team p {
  line-height: 1.2;
  text-align: justify;
}

.about__family {
  background: #f2f2f2;
}

.about__family p {
  text-align: justify;
  line-height: 2.5;
}

@media only screen and (max-width: 768px) {
  .about__main h1 {
    font-size: 36px;
  }
  .about__main h5 {
    font-size: 24px;
  }
  .about__sub__text p {
    font-size: 16px;
  }
  .about__team p {
    /* font-size: 12px; */
  }
}
