.product__section {
  background-color: #1d1d1f;
  height: 102vh;
  /* padding-top: 200px; */
  margin-top: -65px;
  display: flex;
  align-items: center;
}
.product__section__firstCol {
  padding-top: 100px;
}
.product__section h1 {
  color: #277196;
  line-height: 1.5;
  font-weight: bold;
  font-size: 56px;
}

.product__section .MuiButton-root {
  background: #252527;
  color: #0096d6;
  font-weight: 900;
  padding: 10px 30px;
  border-radius: 50px;
}

.gray__bg {
  background: #f2f2f2;
  /* margin-left: 30px;
  margin-right: 30px; */
}

.ohm__smartHome video {
  width: 90%;
  display: block;
  margin: auto;
}

.know__btn {
  background: #277196;
  padding: 10px 30px;
  color: #fff;
  border-radius: 50px;
  display: block;
}

.meet__ohm__section p {
  text-align: start;
}

@media only screen and (max-width: 768px) {
  .product__section {
    height: auto !important;
  }
  .product__section h1 {
    font-size: 32px;
  }
  .ohm__smartHome video {
    width: 100%;
    display: block;
    margin: auto;
  }
}
