.faq__questions h4 {
  color: #59c7a0;
  font-weight: bold;
}

.accordion {
  margin-top: 40px;
}
.accordion .card {
  border: none;
  margin-bottom: 20px;
}
.accordion .card p {
  background: url(https://cdn0.iconfinder.com/data/icons/entypo/91/arrow56-512.png)
    no-repeat calc(100% - 0px) center;
  background-size: 15px;
  cursor: pointer;
  font-size: 14px !important;
  font-weight: bold;
  text-align: start;
}
.accordion .card p.collapsed {
  background-image: url(https://cdn0.iconfinder.com/data/icons/arrows-android-l-lollipop-icon-pack/24/expand2-256.png);
}
.accordion .card-body {
  padding-left: 0;
  padding-right: 0;
}

.accordion .card .collapse {
  width: 90%;
}
