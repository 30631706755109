.compliance {
    height: 70vh;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    color: #212529;
    text-align: justify;
  }

  .clickHere{
    color: #277196 ;
  }
  .clickHere:hover{
    color: #20c997;
  }
  .title{
    font-weight: 400 ;
  }