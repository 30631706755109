.sales-refund h1,
.sales-refund h5 {
  color: #1d1d1f !important;
  line-height: 2;
}

.sales-refund h4 {
  color: #1d1d1f !important;
  font-weight: 600 !important;
}

.sales-refund p,
.sales-refund li {
  font-size: 18px;
  line-height: 2;
  color: #000000;
  text-align: justify;
}

.b__gray {
  background-color: #f2f2f2;
  /* padding: 0px 120px; */
}

@media only screen and (max-width: 768px) {
  .b__gray {
    /* padding: 10px; */
  }
}
